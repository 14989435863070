import {Button, Icon, Tooltip} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {FaNetworkWired} from 'react-icons/fa6';

import {useCurrentIp} from '@app/store/services/ipSlice';

const IpInfo: FC = (): ReactElement => {
  const ip = useCurrentIp();

  let ipLabel = ip === false ? 'Votre IP local est inaccessible' : 'Votre ip est accessible : ' + ip;
  let ipColor = ip === false ? 'redAlpha' : 'greenAlpha';

  return (
    <Tooltip label={ipLabel}>
      <Button colorScheme={ipColor}>
        <Icon as={FaNetworkWired} />
      </Button>
    </Tooltip>
  );
};

export default IpInfo;
