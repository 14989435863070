import {Card, CardBody} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import ReactApexChart from 'react-apexcharts';

import {ChartType} from '@app/types/Statistic/Statistic.ts';

export type ChartPieBlockProps = {
  chart: ChartType;
};

export const ChartColumnBlock: FC<ChartPieBlockProps> = ({chart}): ReactElement => {
  const series = [{data: chart.data}] as ApexAxisChartSeries;
  return (
    <Card variant="elevated">
      <CardBody>
        <ReactApexChart
          options={{
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            xaxis: {categories: [...chart.labels.values()]},
          }}
          series={series}
          type="bar"
          height="400"
          width="600"
        />
      </CardBody>
    </Card>
  );
};
