import {Center, Heading} from '@chakra-ui/react';
import React, {FC} from 'react';
import {useOutletContext} from 'react-router-dom';

import PrinterHistory from '@app/components/atoms/PrinterHistory/PrinterHistory';
import {userAccessContext} from '@app/types/ContextType';

type LayoutWarehouseProps = {
  appName: string;
  children: React.ReactNode;
  needPrinterInfo?: boolean;
};

const LayoutWarehouse: FC<LayoutWarehouseProps> = ({appName, children, needPrinterInfo = false}) => {
  const {userAccess} = useOutletContext<userAccessContext>();

  return (
    <>
      <Center>
        <Heading color="white" size="lg" mb="5" mt="0">
          Entrepôt - {appName}
        </Heading>
      </Center>
      {children}
      {needPrinterInfo && userAccess.debug && <PrinterHistory />}
    </>
  );
};

export default LayoutWarehouse;
