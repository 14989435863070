import {Box, Flex, Image} from '@chakra-ui/react';
import React, {FC, PropsWithChildren} from 'react';

type AuthIllustrationProps = {
  illustrationBackground: string;
  image: string;
};

const LayoutIllustration: FC<PropsWithChildren<AuthIllustrationProps>> = ({
  children,
  illustrationBackground,
  image,
}) => {
  return (
    <Flex position="relative" mb="70px">
      <Flex
        h={{sm: 'initial', md: '75vh', lg: '85vh'}}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="40px"
        pt={{sm: '100px', md: '0px'}}
      >
        {children}
        <Box
          display={{base: 'none', md: 'block'}}
          overflowX="hidden"
          h="100%"
          w={{lg: '50vw', '2xl': '50vw'}}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={illustrationBackground}
            justify="center"
            align="center"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="25%"
            position="absolute"
            borderBottomLeftRadius="20px"
          >
            <Image boxSize={{lg: '500px', xl: '600px', '2xl': '790px'}} src={image} alt="illustration" />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LayoutIllustration;
