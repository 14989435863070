import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {UserAccess} from '@app/types/UserAccess';

export const userAccessApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    userAccess: builder.query<UserAccess, void>({
      query: () => ({
        url: Routing.generate('rest_user_access', {}, true),
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useUserAccessQuery} = userAccessApi;
