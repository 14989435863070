import React, {FC, ReactElement} from 'react';
import {FaClipboardList, FaInbox, FaPaperPlane, FaPrint} from 'react-icons/fa';

import ButtonActionList from '@app/components/molecules/ButtonActionList';
import {ButtonType} from '@app/types/Common/ButtonType';
import {Role} from '@app/types/RoleEnum';
import {Warehouse} from '@app/types/Warehouse';

const WarehouseActions: FC<{warehouse: Warehouse}> = ({warehouse}): ReactElement => {
  const lines: ButtonType[][] = [
    [
      {
        label: 'Inventaire',
        icon: <FaClipboardList />,
        link: `/warehouse/inventory`,
        role: Role.Inventory,
        color: 'red',
        enabled: false,
      },
      {
        label: 'Transfert / Rangement',
        icon: <FaPaperPlane />,
        link: `/warehouse/transfert`,
        role: Role.Transfer,
        color: 'red',
        enabled: false,
      },
    ],
    [
      {
        label: 'Impression produit',
        icon: <FaPrint />,
        link: `/warehouse/print-product`,
        role: Role.Barcode,
        color: 'blue',
        enabled: false,
      },
      {
        label: 'Impression emplacement',
        icon: <FaPrint />,
        link: `/warehouse/print-location`,
        role: Role.Barcode,
        color: 'blue',
        enabled: true,
      },
      {
        label: 'Impression caisse',
        icon: <FaPrint />,
        link: `/warehouse/print-box`,
        role: Role.Barcode,
        color: 'blue',
        enabled: true,
      },
    ],
    [
      {
        label: 'Borderau transport',
        icon: <FaInbox />,
        link: `/warehouse/carrier-slip`,
        role: Role.Barcode,
        color: 'green',
        enabled: true,
      },
    ],
  ];

  return <ButtonActionList lines={lines} roles={warehouse.roles!} />;
};

export default WarehouseActions;
