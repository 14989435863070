import React, {FC, ReactElement} from 'react';

import {ButtonAction} from '@app/components/atoms/Button/ButtonAction';

type ButtonActionProps = {
  count: number;
  isLoading: boolean;
  isMono?: boolean;
  label?: string;
  onClick: () => void;
};

const PickingButton: FC<ButtonActionProps> = ({count, isLoading, onClick, isMono, label}): ReactElement => {
  let newLabel;
  if (isMono) {
    newLabel = label ?? 'Mono - ' + count;
  } else if (label !== undefined) {
    newLabel = label;
  } else {
    newLabel = 'Multi - ' + count;
  }

  return <ButtonAction name={newLabel} onClick={onClick} isDisabled={count === 0} isLoading={isLoading} />;
};

export default PickingButton;
