import React, {FC, ReactElement} from 'react';

import ShipmentApp from '@app/components/Shop/Shipment/ShipmentApp';
import LayoutShop from '@app/components/templates/LayoutShop';

const ShopShipping: FC = (): ReactElement => {
  return (
    <LayoutShop appName="Expédition" needPrinterInfo>
      <ShipmentApp />
    </LayoutShop>
  );
};

export default ShopShipping;
