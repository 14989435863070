import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {LocationListResponse} from '@app/types/Location/LocationListResponse';
import {Stock} from '@app/types/Stock/Stock';

export const printerApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    locationFindByPrefix: builder.query<LocationListResponse, string>({
      query: prefix => ({
        url: Routing.generate('rest_location_find_prefix', {prefix}, true),
        method: 'GET',
      }),
    }),
    locationStock: builder.mutation<Stock, {shopSlug: string; locationSlug: string}>({
      query: ({shopSlug, locationSlug}) => ({
        url: Routing.generate('rest_shop_location_stock', {shopSlug, locationSlug}, true),
        method: 'GET',
      }),
    }),
  }),
});

export const {useLazyLocationFindByPrefixQuery, useLocationStockMutation} = printerApi;
