// @ts-nocheck
/**
 * Get Local IP Address
 *
 * @returns Promise Object
 *
 * localIp().then((ipAddr) => {
 *    console.log(ipAddr); // 192.168.0.122
 * });
 */
export default function localIp() {
  return new Promise<string>(function (resolve, reject) {
    // NOTE: window.RTCPeerConnection is "not a constructor" in FF22/23
    // @ts-ignore
    let RTCPeerConnection =
      /*window.RTCPeerConnection ||*/ window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

    if (!RTCPeerConnection) {
      reject('Your browser does not support this API');
    }

    let rtc = new RTCPeerConnection({iceServers: []});
    let addrs = {};
    addrs['0.0.0.0'] = false;

    function grepSDP(sdp) {
      let finalIP = '';
      sdp.split('\r\n').forEach(function (line) {
        // c.f. http://tools.ietf.org/html/rfc4566#page-39
        if (~line.indexOf('a=candidate')) {
          // http://tools.ietf.org/html/rfc4566#section-5.13
          let parts = line.split(' '), // http://tools.ietf.org/html/rfc5245#section-15.1
            addr = parts[4],
            type = parts[7];
          if (type === 'host') {
            finalIP = addr;
          }
        } else if (~line.indexOf('c=')) {
          // http://tools.ietf.org/html/rfc4566#section-5.7
          let parts = line.split(' '),
            addr = parts[2];
          finalIP = addr;
        }
      });
      return finalIP;
    }

    // @ts-ignore
    if (1 || window.mozRTCPeerConnection) {
      // FF [and now Chrome!] needs a channel/stream to proceed
      rtc.createDataChannel('', {reliable: false});
    }

    rtc.onicecandidate = function (evt) {
      // convert the candidate to SDP so we can run it through our general parser
      // see https://twitter.com/lancestout/status/525796175425720320 for details
      if (evt.candidate) {
        let addr = grepSDP('a=' + evt.candidate.candidate);
        resolve(addr);
      }
    };
    rtc.createOffer(
      function (offerDesc) {
        rtc.setLocalDescription(offerDesc);
      },
      function (e) {
        console.warn('offer failed', e);
      },
    );
  });
}
