import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import LayoutShop from '@app/components/templates/LayoutShop';
import {Shop} from '@app/types/Shop';

type ContextType = {shop: Shop};

const ShopProductInformation: FC = (): ReactElement => {
  const {shop} = useOutletContext<ContextType>();

  return <LayoutShop appName="Information produit">Information produit {shop.name}</LayoutShop>;
};

export default ShopProductInformation;
