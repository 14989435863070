import {Spinner} from '@chakra-ui/react';
import React, {FC, ReactElement, Suspense} from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import AuthGuard from '@app/components/Guard/AuthGuard';
import Dashboard from '@app/layouts/Dashboard';
import ShopIndex from '@app/page/Shop/ShopIndex';
import ShopOrderInformation from '@app/page/Shop/ShopOrderInformation';
import ShopOrderReturn from '@app/page/Shop/ShopOrderReturn';
import ShopPicking from '@app/page/Shop/ShopPicking';
import ShopProductInformation from '@app/page/Shop/ShopProductInformation';
import ShopRebut from '@app/page/Shop/ShopRebut';
import ShopReception from '@app/page/Shop/ShopReception';
import ShopShipping from '@app/page/Shop/ShopShipping';
import ShopWrapper from '@app/page/Shop/ShopWrapper';
import SignIn from '@app/page/SignIn';
import StatisticIndex from '@app/page/Statistic/StatisticIndex';
import WarehouseCarrierSlip from '@app/page/Warehouse/WarehouseCarrierSlip';
import WarehouseIndex from '@app/page/Warehouse/WarehouseIndex';
import WarehouseInventory from '@app/page/Warehouse/WarehouseInventory';
import WarehousePrintBox from '@app/page/Warehouse/WarehousePrintBox';
import WarehousePrintLocation from '@app/page/Warehouse/WarehousePrintLocation';
import WarehouseWrapper from '@app/page/Warehouse/WarehouseWrapper';

const DashboardIndex = React.lazy(() => import('@app/page/DashboardIndex'));

const App: FC = (): ReactElement => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
      children: [
        {
          path: 'shop/:shopSlug/*',
          element: <ShopWrapper />,
          children: [
            {
              index: true,
              element: <ShopIndex />,
            },
            {
              path: 'picking',
              element: <ShopPicking />,
            },
            {
              path: 'shipping',
              element: <ShopShipping />,
            },
            {
              path: 'reception',
              element: <ShopReception />,
            },
            {
              path: 'rebut',
              element: <ShopRebut />,
            },
            {
              path: 'product-information',
              element: <ShopProductInformation />,
            },
            {
              path: 'order-return',
              element: <ShopOrderReturn />,
            },
            {
              path: 'order-information',
              element: <ShopOrderInformation />,
            },
          ],
        },
        {
          path: 'warehouse/*',
          element: <WarehouseWrapper />,
          children: [
            {
              index: true,
              element: <WarehouseIndex />,
            },
            {
              path: 'inventory',
              element: <WarehouseInventory />,
            },
            {
              path: 'print-location',
              element: <WarehousePrintLocation />,
            },
            {
              path: 'print-box',
              element: <WarehousePrintBox />,
            },
            {
              path: 'carrier-slip',
              element: <WarehouseCarrierSlip />,
            },
          ],
        },
        {
          path: 'statistic',
          element: <StatisticIndex />,
        },
        {
          path: '',
          element: (
            <Suspense>
              <DashboardIndex />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <SignIn />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} fallbackElement={<Spinner />} />
    </>
  );
};

export default App;
