import {Box, ChakraProps, Flex, HTMLChakraProps, Image, OmitCommonProps, Stack} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {NavLink} from 'react-router-dom';

import Logo from '@app/assets/images/logo.png';
import {HSeparator} from '@app/components/atoms/Separator/Separator';
import SidebarItems from '@app/layouts/Sidebar/SidebarItems';
import {UserAccess} from '@app/types/UserAccess';

const SidebarContent: FC<
  React.JSX.IntrinsicAttributes &
    OmitCommonProps<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, keyof ChakraProps> &
    ChakraProps & {as?: ('div' & HTMLChakraProps<'div'>) | undefined} & {userAccess: UserAccess}
> = (data): ReactElement => {
  const {userAccess} = data;
  return (
    <>
      <Box>
        <Box>
          <Flex align="center" direction="column" pt={'25px'}>
            <NavLink to={`/`}>
              <Image src={Logo} alt="logo" w="150px" />
            </NavLink>
            <HSeparator my="20px" />
          </Flex>
        </Box>
        <Stack direction="column" mb="40px">
          <Box>
            <SidebarItems userAccess={userAccess} />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
export default SidebarContent;
