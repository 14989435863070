import {Card, CardBody} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import ReactApexChart from 'react-apexcharts';

import {ChartType} from '@app/types/Statistic/Statistic.ts';

export type ChartPieBlockProps = {
  chart: ChartType;
};

export const ChartRadialBarBlock: FC<ChartPieBlockProps> = ({chart}): ReactElement => {
  return (
    <Card variant="elevated">
      <CardBody>
        <ReactApexChart
          options={{
            labels: chart.labels,
            chart: {
              height: 350,
              type: 'radialBar',
              offsetY: -10,
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                dataLabels: {
                  name: {
                    fontSize: '16px',
                    color: undefined,
                    offsetY: 20,
                  },
                  value: {
                    offsetY: 30,
                    fontSize: '22px',
                    color: undefined,
                  },
                },
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91],
              },
            },
            stroke: {
              dashArray: 4,
            },
          }}
          series={chart.data}
          type="radialBar"
          width={380}
        />
      </CardBody>
    </Card>
  );
};
