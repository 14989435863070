import {setupListeners} from '@reduxjs/toolkit/query/react';
import {configureStore} from '@reduxjs/toolkit/react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';

import camaloModuleApi from '@app/store/camaloModuleApi';
import printerLog from '@app/store/printerLogSlice';
import {authApi} from '@app/store/services/authApi';
import auth from '@app/store/services/authSlice';
import inputFocus from '@app/store/services/inputFocusSlice';
import ip from '@app/store/services/ipSlice';
import qz from '@app/store/services/qzSlice';

export const store = configureStore({
  reducer: {
    [camaloModuleApi.reducerPath]: camaloModuleApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    auth,
    ip,
    qz,
    printerLog,
    inputFocus,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([camaloModuleApi.middleware, authApi.middleware]),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
