import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {Order} from '@app/types/Order/Information/Order';

type SearchOrderQuery = {
  shopSlug: string;
  query: string;
};

export const orderApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    searchOrder: builder.query<Order, SearchOrderQuery>({
      query: ({shopSlug, query}) => ({
        url: Routing.generate('rest_order_search', {slug: shopSlug, query}, true),
        method: 'GET',
      }),
    }),
  }),
});

export const {useLazySearchOrderQuery} = orderApi;
