import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const xl = defineStyle({
  fontSize: 'xl',
  px: '6',
  h: '16',
  borderRadius: 'md',
});
const x4l = defineStyle({
  fontSize: '4xl',
  px: '6',
  h: '16',
  borderRadius: 'md',
});

export const buttonTheme = defineStyleConfig({
  sizes: {
    xl,
    '4xl': x4l,
  },
});
