import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {CarrierSlip} from '@app/types/CarrierSlip/CarrierSlip';
import {PrintElement} from '@app/types/PrintElement';

export const carrierSlipApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    carrierSlipList: builder.query<CarrierSlip[], void>({
      query: () => ({
        url: Routing.generate('rest_carrier_slip_list', {}, true),
        method: 'GET',
      }),
    }),
    carrierSlipCreate: builder.mutation<PrintElement, CarrierSlip>({
      query: carrierSlip => ({
        url: Routing.generate('rest_carrier_slip_create', {carrierSlipGroup: carrierSlip.id}, true),
        method: 'POST',
      }),
    }),
  }),
});

export const {useCarrierSlipListQuery, useCarrierSlipCreateMutation} = carrierSlipApi;
