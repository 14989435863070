import {Box, Flex, Heading, Text, useColorModeValue} from '@chakra-ui/react';
import React, {FC, ReactElement, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import IMAGES from '@app/assets/images';
import Login from '@app/components/Login/Login';
import LayoutIllustration from '@app/components/templates/LayoutIllustration';
import {useToken} from '@app/store/useToken';

const SignIn: FC = (): ReactElement => {
  const textColor = useColorModeValue('gray.400', 'white');
  const titleColor = useColorModeValue('red.500', 'red.500');
  const {token} = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  return (
    <LayoutIllustration illustrationBackground={'#D71921'} image={IMAGES.signIn}>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="center"
        mb={{base: '30px', md: '60px'}}
        px={{base: '25px', md: '0px'}}
        mt={{base: '40px', md: '24vh'}}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={titleColor} fontSize="2rem" mb="1rem">
            Chamalo
          </Heading>
          <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize={'sm'}>
            Une application de Camalo
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{base: '100%', md: '350px'}}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          me="auto"
          mb={{base: '20px', md: 'auto'}}
        >
          <Login />
        </Flex>
      </Flex>
    </LayoutIllustration>
  );
};

export default SignIn;
