import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import React, {ChangeEvent, FC, KeyboardEvent, ReactElement, useEffect, useRef, useState} from 'react';

const AlertQuantity: FC<{
  needOpen: boolean;
  quantity: (data: number) => void;
}> = ({needOpen, quantity}): ReactElement => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const okRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState<number>(1);

  const resetValue = (): void => {
    setValue(1);
  };

  useEffect(() => {
    if (needOpen && !isOpen) {
      onOpen();
    }
  }, [isOpen, needOpen, onOpen]);

  const updateValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(Number.parseInt(e.target.value));
  };

  const handleCancel = (): void => {
    quantity(0);
    onClose();
    resetValue();
  };

  const handleOk = (): void => {
    quantity(value);
    onClose();
    resetValue();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      quantity(value);
      onClose();
      resetValue();
    }
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={okRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Quantité
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input
                placeholder={'Quantité à imprimer'}
                size="3xl"
                variant="flushed"
                onChange={e => updateValue(e)}
                onKeyDown={e => handleKeyDown(e)}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={okRef} onClick={handleOk}>
                Ok
              </Button>
              <Button colorScheme="red" onClick={handleCancel} ml={3}>
                Annuler
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AlertQuantity;
