import {createContext} from 'react';

export interface SidebarContextProps {
  sidebarWidth: number;
  setSidebarWidth: (width: number) => void;
  toggleSidebar: boolean;
  setToggleSidebar: (toggle: boolean) => void;
}

export const SidebarContext = createContext({} as SidebarContextProps);
