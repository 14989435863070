import {replaceItemAtIndex} from '@app/helpers';
import {useAppDispatch} from '@app/store';
import {pickingApi} from '@app/store/camalo/pickingApi';
import {userAccessApi} from '@app/store/camalo/userAccess/userAccessApi';
import {Shop} from '@app/types/Shop';

export const useUpdateOrderCount = () => {
  const dispatch = useAppDispatch();
  const updateOrderCount = (shop: Shop, reduceOrderCount: number, pickingType: string): void => {
    dispatch(
      userAccessApi.util.updateQueryData('userAccess', undefined, userAccess => {
        userAccess.shops = replaceItemAtIndex(
          userAccess.shops!,
          userAccess.shops!.findIndex(c => c.slug === shop.slug!),
          {
            ...shop,
            orderCount: shop.orderCount! - reduceOrderCount,
          },
        );
      }),
    );
    dispatch(
      pickingApi.util.updateQueryData('pickingCount', shop.slug!, pickingCount => {
        if (pickingType === 'picking mono') {
          pickingCount.countMono -= reduceOrderCount;
        }
        if (pickingType === 'picking multi') {
          pickingCount.countMulti! -= reduceOrderCount;
        }
      }),
    );
  };

  return {updateOrderCount};
};
