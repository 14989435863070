import {Center, Heading} from '@chakra-ui/react';
import React, {FC} from 'react';
import {useOutletContext} from 'react-router-dom';

import PrinterHistory from '@app/components/atoms/PrinterHistory/PrinterHistory';
import {shopContext} from '@app/types/ContextType';

type LayoutShopProps = {
  appName: string;
  children: React.ReactNode;
  needPrinterInfo?: boolean;
};

const LayoutShop: FC<LayoutShopProps> = ({appName, children, needPrinterInfo = false}) => {
  const {shop, userAccess} = useOutletContext<shopContext>();

  return (
    <>
      <Center>
        <Heading color="white" size="lg" mb="5" mt="0">
          {shop.name} - {appName}
        </Heading>
      </Center>
      {children}
      {needPrinterInfo && userAccess.debug && <PrinterHistory />}
    </>
  );
};

export default LayoutShop;
