import {Grid} from '@chakra-ui/react';
import {captureException} from '@sentry/react';
import {useOutletContext} from 'react-router-dom';

import PickingButton from '@app/components/Shop/Picking/_partial/PickingButton';
import {ButtonActionSkeleton} from '@app/components/atoms/Button/ButtonAction';
import {
  useLazyPickingCreateMonoQuery,
  useLazyPickingCreateMultiQuery,
  usePickingCountQuery,
} from '@app/store/camalo/pickingApi';
import {useUpdateOrderCount} from '@app/store/camalo/userAccess/UpdateOrderCount';
import {usePrinterLog} from '@app/store/printerLogSlice';
import {shopContext} from '@app/types/ContextType';
import {PrinterHandler} from '@app/utils/PrinterHandler';
import {findPrinterByType} from '@app/utils/PrinterState';

const PickingDefault = () => {
  const {shop, printerList} = useOutletContext<shopContext>();
  const {updateOrderCount} = useUpdateOrderCount();
  const {addLogFromPrinterElement, logs} = usePrinterLog();
  const {data: pickingCount, isLoading: pickingCountLoading, isError} = usePickingCountQuery(shop.slug!);

  const [triggerPickingMono, {isLoading: pickingMonoLoading}] = useLazyPickingCreateMonoQuery();
  const [triggerPickingMulti, {isLoading: pickingMultiLoading}] = useLazyPickingCreateMultiQuery();

  if (pickingCountLoading) {
    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={12}>
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
      </Grid>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  const updateMonoCount = async (pickingType: string): Promise<void> => {
    try {
      let response;
      switch (pickingType) {
        case 'picking mono':
          response = await triggerPickingMono(shop.slug!).unwrap();
          break;
        case 'picking multi':
          response = await triggerPickingMulti(shop.slug!).unwrap();
          break;
      }
      if (response === null || response === undefined) {
        return;
      }

      const responseOrderCount =
        (pickingType === 'picking mono' ? pickingCount!.countMono : pickingCount!.countMulti) - response.orderCountLeft;

      updateOrderCount(shop, responseOrderCount, pickingType);
      addLogFromPrinterElement(response.printElement, pickingType);
      const printer = findPrinterByType(printerList, response.printElement.printer);
      if (printer !== undefined) {
        PrinterHandler.pdf(printer.originalName, response.printElement.url);
      }
    } catch (error) {
      captureException(error);
      console.log(error);
      console.error('createPickingMono -> PickingApi.createMono()');
    }
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={12}>
      <PickingButton
        count={pickingCount!.countMono}
        onClick={() => updateMonoCount('picking mono')}
        isMono
        isLoading={pickingMonoLoading}
      />
      <PickingButton
        count={pickingCount!.countMulti}
        onClick={() => updateMonoCount('picking multi')}
        isLoading={pickingMultiLoading}
      />
    </Grid>
  );
};

export default PickingDefault;
