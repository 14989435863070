import {Box, useStyleConfig} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

const PanelContent: FC<{[x: string]: any; children: any}> = (props): ReactElement => {
  const {variant, children, ...rest} = props;
  const styles = useStyleConfig('PanelContent', {variant: 'main'});
  return (
    <Box __css={styles} minH="100vh" {...rest}>
      {children}
    </Box>
  );
};
export default PanelContent;
