import React from 'react';

// @ts-ignore
export const renderTrack = ({style, ...props}) => {
  const trackStyle = {
    position: 'absolute',
    maxWidth: '100%',
    width: 6,
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  };
  return <div style={{...style, ...trackStyle}} {...props} />;
};

// @ts-ignore
export const renderThumbDark = ({style, ...props}) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(222, 222, 222, .1)',
  };
  return <div style={{...style, ...thumbStyle}} {...props} />;
};

// @ts-ignore
export const renderThumbLight = ({style, ...props}) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(48, 48, 48, .1)',
  };
  return <div style={{...style, ...thumbStyle}} {...props} />;
};

// @ts-ignore
export const renderView = ({style, ...props}) => {
  const viewStyle = {
    //marginRight: -25,
  };
  return <div style={{...style, ...viewStyle}} {...props} />;
};
