import {HamburgerIcon} from '@chakra-ui/icons';
import {Box, Flex, useColorMode} from '@chakra-ui/react';
import * as CSS from 'csstype';
import React, {FC, ReactElement, useContext, useEffect, useState} from 'react';

import HeaderLinks from '@app/layouts/Navbar/HeaderLinks';
import {SidebarContext} from '@app/layouts/Sidebar/SideBarContext';
import SidebarResponsive from '@app/layouts/Sidebar/SidebarResponsive';
import {UserAccess} from '@app/types/UserAccess';

const AdminNavbar: FC<{[x: string]: any; fixed: any; userAccess: UserAccess}> = (props): ReactElement => {
  const {colorMode} = useColorMode();
  const [scrolled, setScrolled] = useState(false);
  const {sidebarWidth, setSidebarWidth, toggleSidebar, setToggleSidebar} = useContext(SidebarContext);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const {fixed, userAccess} = props;

  let mainText = fixed && scrolled ? 'gray.700' : 'white';
  let secondaryText = fixed && scrolled ? 'gray.700' : 'white';

  if (colorMode === 'dark') {
    mainText = fixed && scrolled ? 'gray.200' : 'gray.200';
    secondaryText = fixed && scrolled ? 'gray.200' : 'gray.200';
  }

  let navbarPosition = 'absolute';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = 'none';
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  if (props.fixed === true && scrolled) {
    navbarPosition = 'fixed';
    navbarShadow = colorMode === 'light' ? '0px 7px 23px rgba(0, 0, 0, 0.05)' : 'none';
    navbarBg =
      colorMode === 'light'
        ? 'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)'
        : 'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)';
    navbarBorder = colorMode === 'light' ? '#FFFFFF' : 'rgba(255, 255, 255, 0.31)';
    navbarFilter = colorMode === 'light' ? 'none' : 'drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))';
  }
  if (props.secondary) {
    navbarBackdrop = 'none';
    navbarPosition = 'absolute';
    mainText = 'white';
    secondaryText = 'white';
    secondaryMargin = '22px';
    paddingX = '30px';
  }
  const changeNavbar = () => {
    setScrolled(window.scrollY > 1);
  };

  return (
    <Flex
      position={navbarPosition as CSS.Property.Position}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      borderRadius="16px"
      display="flex"
      justifyContent={{xl: 'space-between'}}
      lineHeight="25.6px"
      mx="30px"
      mt={secondaryMargin}
      px={{
        sm: paddingX,
        md: '30px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top={{
        base: 4,
        md: '8px',
      }}
      w={{base: 'calc(100vw - 60px)', xl: 'calc(100vw - 75px - ' + sidebarWidth + 'px)'}}
      flexDirection={{
        sm: 'row',
        md: 'row',
      }}
      alignItems="center"
      right="0"
    >
      <SidebarResponsive userAccess={userAccess} display={{base: 'none', xl: 'block'}} />
      <HamburgerIcon
        w="30px"
        h="20px"
        color="#fff"
        cursor="pointer"
        display={{base: 'none', xl: 'block'}}
        onClick={() => {
          setSidebarWidth(sidebarWidth === 275 ? 120 : 275);
          setToggleSidebar(!toggleSidebar);
        }}
      />
      <HeaderLinks userAccess={userAccess} />
    </Flex>
  );
};
export default AdminNavbar;
