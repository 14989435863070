// @ts-ignore
import qz from 'qz-tray';

export class PrinterHandler {
  static pdf(printer: string, assetUrl: string, copies: number = 1): void {
    const config = qz.configs.create(printer, {copies: copies});
    qz.print(config, [
      {
        type: 'pixel',
        format: 'pdf',
        flavor: 'file',
        data: assetUrl,
      },
    ]);
  }

  static zpl(printer: string, zpl: string[], copies: number = 1): void {
    // @ts-ignore
    const config = qz.configs.create(printer, {copies: copies, language: 'ZPL', altPrinting: true});
    qz.print(config, zpl);
  }
}
