import React, {FC, ReactElement} from 'react';
import {Outlet, useOutletContext} from 'react-router-dom';

import {userAccessContext} from '@app/types/ContextType';

const WarehouseWrapper: FC = (): ReactElement => {
  const {userAccess, printerList} = useOutletContext<userAccessContext>();
  return <Outlet context={{warehouse: userAccess.warehouse!, userAccess, printerList}} />;
};

export default WarehouseWrapper;
