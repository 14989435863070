import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {TOKEN} from '@app/store/useToken';

const camaloModuleApi = createApi({
  reducerPath: 'camaloModuleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: headers => {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        headers.set('authorization', `Bearer ${JSON.parse(token)}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Camalo', 'pickingListCampaign'],
  endpoints: () => ({}),
});

export default camaloModuleApi;
