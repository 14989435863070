import {Box, HStack, Heading, Stack, VStack} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

import {ChartColumnBlock} from '@app/components/atoms/Statistic/ChartColumnBlock.tsx';
import {ChartPieBlock} from '@app/components/atoms/Statistic/ChartPieBlock.tsx';
import {ChartRadialBarBlock} from '@app/components/atoms/Statistic/ChartRadialBarBlock.tsx';
import {StatTitleBlock} from '@app/components/atoms/Statistic/StatTitleBlock.tsx';
import {StatBlockList} from '@app/components/molecules/Statistic/StatBlockList.tsx';
import {useTodayQuery} from '@app/store/camalo/statisticApi.ts';
import {BlockType, ChartPieType, ChartType, TitleBlockType} from '@app/types/Statistic/Statistic.ts';

const StatisticIndex: FC = (): ReactElement => {
  const {data, isLoading} = useTodayQuery();

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <VStack spacing="5">
      {data!.rows.map((row, index) => {
        return (
          <HStack spacing="5" key={index}>
            {row.blocks.map((block, index) => {
              return (
                <HStack flexGrow={block.flexGrow} key={index}>
                  {block.type === 'stat_block_title' && <StatTitleBlock title={(block as TitleBlockType).title} />}
                  {block.type === 'stat_block_list' && <StatBlockList blocks={(block as BlockType).statBlocks} />}
                  {block.type === 'stat_chart_pie' && <ChartPieBlock chart={block as ChartType} />}
                  {block.type === 'stat_chart_column' && <ChartColumnBlock chart={block as ChartType} />}
                  {block.type === 'stat_chart_radial_bar' && <ChartRadialBarBlock chart={block as ChartType} />}
                </HStack>
              );
            })}
          </HStack>
        );
      })}
    </VStack>
  );
};

export default StatisticIndex;
