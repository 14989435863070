import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';

export const computerApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    computerExist: builder.query<boolean, void>({
      query: () => ({
        url: Routing.generate('rest_computer_exist', {}, true),
        method: 'HEAD',
        extraOptions: {maxRetries: 0},
      }),
    }),
  }),
});

export const {useComputerExistQuery} = computerApi;
