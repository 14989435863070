import {StackDivider, VStack} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

import PrintBox from '@app/components/Warehouse/Print/Box/PrintBox';
import PrintBoxPrefix from '@app/components/Warehouse/Print/Box/PrintBoxPrefix';
import LayoutWarehouse from '@app/components/templates/LayoutWarehouse';

const WarehousePrintBox: FC = (): ReactElement => {
  return (
    <LayoutWarehouse appName="Impression caisse" needPrinterInfo>
      <VStack divider={<StackDivider borderColor="gray.200" />} spacing={10} align="stretch">
        <PrintBox />
        <PrintBoxPrefix />
      </VStack>
    </LayoutWarehouse>
  );
};

export default WarehousePrintBox;
