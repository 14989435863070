import {Center, Stat, StatLabel, StatNumber} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

export type StatBlockProps = {
  title: string;
  value: string | number;
};

export const StatBlock: FC<StatBlockProps> = ({title, value}): ReactElement => {
  return (
    <Stat>
      <StatLabel>{title}</StatLabel>
      <Center>
        <StatNumber>{value}</StatNumber>
      </Center>
    </Stat>
  );
};
