import {Heading} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import ShopActions from '@app/components/Shop/ShopActions';
import {shopContext} from '@app/types/ContextType';

const ShopIndex: FC = (): ReactElement => {
  const {shop} = useOutletContext<shopContext>();

  return (
    <>
      <Heading as="h3" size="lg" textAlign="center" color="white" mb={7} mt="0">
        {shop.name}
      </Heading>
      <ShopActions shop={shop} />
    </>
  );
};

export default ShopIndex;
