import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

import App from '@app/page/App';
import {store} from '@app/store';
import theme from '@app/theme';

Sentry.init({
  dsn: 'https://bd4ae2faa62f4749bd71dc01239d54a1@o217106.ingest.sentry.io/4505590642507776',
  tracePropagationTargets: ['localhost', 'https:chamalo.camalo.fr/rest/'],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </Provider>
  </ChakraProvider>,
);
