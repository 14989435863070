import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers, defineStyle} from '@chakra-ui/react';
import {mode} from '@chakra-ui/theme-tools';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const xl = defineStyle({
  fontSize: 'xl',
  px: '4',
  h: '12',
});
const x2l = defineStyle({
  fontSize: '2xl',
  px: '4',
  h: '12',
});
const x3l = defineStyle({
  fontSize: '3xl',
  px: '6',
  h: '12',
});
const x5l = defineStyle({
  fontSize: '5xl',
  px: '6',
  h: '16',
});

const sizes = {
  xl: definePartsStyle({field: xl, addon: xl}),
  '2xl': definePartsStyle({field: x2l, addon: x2l}),
  '3xl': definePartsStyle({field: x3l, addon: x3l}),
  '5xl': definePartsStyle({field: x5l, addon: x5l}),
};

export const inputTheme = defineMultiStyleConfig({
  sizes,
  variants: {
    authSecondary: props => ({
      field: {
        bg: mode('white', 'navy.800')(props),
        border: '1px solid',
        borderColor: mode('gray.200', 'transparent')(props),
        _placeholder: {color: mode('gray.300', 'gray.400')(props)},
      },
    }),
  },
});
