import {Button, FormControl, FormLabel, Input, Spinner, Stack, useToast} from '@chakra-ui/react';
import React, {ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch} from '@app/store';
import {useLoginMutation} from '@app/store/services/authApi';
import {setCredentials} from '@app/store/services/authSlice';
import {useCurrentIp} from '@app/store/services/ipSlice';
import {useToken} from '@app/store/useToken';
import {UserLogin} from '@app/types/UserLogin';

const Login = (): ReactElement => {
  const dispatch = useAppDispatch();
  const ip = useCurrentIp() as string;
  const [login, {isLoading}] = useLoginMutation();
  const {token, setToken} = useToken();
  const [user, setUser] = useState<UserLogin>({});
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({token}));
      navigate('/');
    }
  }, []);

  const updateUser = (e: ChangeEvent<HTMLInputElement>): void => {
    setUser({...user, [e.target.name]: e.target.value});
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  const submit = async () => {
    setUser({
      ...user,
      ip,
    });

    try {
      const response = await login({
        ...user,
        ip,
      }).unwrap();
      if (response) {
        setToken(response.token!);
        toast({
          position: 'top-right',
          title: 'Connexion réussie',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            Nom d'utilisateur
          </FormLabel>
          <Input
            id="username"
            fontSize="sm"
            ms={{base: '0px', md: '4px'}}
            placeholder="Nom d'utilisateur"
            mb="24px"
            size="lg"
            type="text"
            name="username"
            variant="authSecondary"
            onChange={e => updateUser(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            Mot de passe
          </FormLabel>
          <Input
            id="password"
            fontSize="sm"
            ms={{base: '0px', md: '4px'}}
            placeholder="Mot de passe"
            mb="24px"
            size="lg"
            type="password"
            name="password"
            variant="authSecondary"
            onChange={e => updateUser(e)}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <Stack spacing={10}>
          <Button
            bg={'red.500'}
            color={'white'}
            _hover={{
              bg: 'red.400',
            }}
            onClick={e => submit()}
            disabled={isLoading}
          >
            Connexion
            {isLoading && <Spinner ml="3" />}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Login;
