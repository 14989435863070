import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {Printer} from '@app/types/Printer';

export const printerApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    printerList: builder.query<Printer[], void>({
      query: () => ({
        url: Routing.generate('rest_printer_list', {}, true),
        method: 'GET',
      }),
    }),
  }),
});

export const {usePrinterListQuery} = printerApi;
