import {Heading} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

export type StatTitleBlockProps = {
  title: string;
};

export const StatTitleBlock: FC<StatTitleBlockProps> = ({title}): ReactElement => {
  return <Heading>{title}</Heading>;
};
