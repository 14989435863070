import {StackDivider, VStack} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

import PrintLocation from '@app/components/Warehouse/Print/Location/PrintLocation';
import PrintLocationPrefix from '@app/components/Warehouse/Print/Location/PrintLocationPrefix';
import LayoutWarehouse from '@app/components/templates/LayoutWarehouse';

const WarehousePrintLocation: FC = (): ReactElement => {
  return (
    <LayoutWarehouse appName="Imprimer emplacement" needPrinterInfo>
      <VStack divider={<StackDivider borderColor="gray.200" />} spacing={10} align="stretch">
        <PrintLocation />
        <PrintLocationPrefix />
      </VStack>
    </LayoutWarehouse>
  );
};

export default WarehousePrintLocation;
