import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import RebutApp from '@app/components/Shop/Rebut/RebutApp';
import LayoutShop from '@app/components/templates/LayoutShop';
import {Shop} from '@app/types/Shop';

type ContextType = {shop: Shop};

const ShopRebut: FC = (): ReactElement => {
  const {shop} = useOutletContext<ContextType>();

  return (
    <LayoutShop appName="Rebut">
      <RebutApp />
    </LayoutShop>
  );
};

export default ShopRebut;
