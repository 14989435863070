import {Box, HStack, Icon, Text, useColorModeValue} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

const NavItem: FC<{[x: string]: any; isActive: boolean; icon?: any; children: any; iconWidth: string}> = (
  props,
): ReactElement => {
  const {icon, children, isActive, iconWidth} = props;
  let variantChange = '0.2s linear';
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('gray.400', 'gray.400');
  return (
    <HStack spacing="14px" py="5px" px="5px">
      <Icon color="blue.500" h="20px" w={iconWidth} transition={variantChange} as={icon} />
      <Text color={isActive ? activeColor : inactiveColor} fontWeight={isActive ? 'bold' : 'normal'} fontSize="md">
        {children}
      </Text>
    </HStack>
  );
};
export default NavItem;
