import {Button} from '@chakra-ui/react';
import {ThemeTypings} from '@chakra-ui/styled-system';
import React, {FC, PropsWithChildren} from 'react';
import {FaSync} from 'react-icons/fa';

type ButtonActionType = {
  name: string;
  color?: ThemeTypings['colorSchemes'];
  isDisabled?: boolean;
  isLoading?: boolean;
  displayBlock?: boolean;
  onClick: () => void;
};

export const ButtonAction: FC<PropsWithChildren<ButtonActionType>> = ({
  name,
  color = 'red',
  isDisabled = false,
  isLoading = false,
  displayBlock = false,
  onClick,
}) => {
  const html = (
    <>
      <span dangerouslySetInnerHTML={{__html: name}} />
      {isLoading ? <FaSync className="ml-3 h-5 w-5 animate-spin" /> : ''}
    </>
  );

  const sxDisplayBlock = displayBlock ? {minWidth: '100%'} : {};

  return (
    <Button
      colorScheme={color}
      size="xl"
      disabled={isDisabled}
      onClick={() => onClick()}
      sx={{textTransform: 'uppercase', ...sxDisplayBlock}}
    >
      {html}
    </Button>
  );
};

export const ButtonActionSkeleton: FC = () => {
  return (
    <Button isLoading size="xl" sx={{minWidth: '100%'}}>
      Loading...
    </Button>
  );
};
