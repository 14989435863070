import {Button, Icon, Tooltip} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {FaPrint} from 'react-icons/fa6';

import {useHasQz} from '@app/store/services/qzSlice';

const QzInfo: FC = (): ReactElement => {
  const qz = useHasQz();
  let qzLabel = qz === false ? 'Merci de lancer le logiciel QZ Tray' : 'Vous pouvez imprimer';
  let qzColor = qz === false ? 'redAlpha' : 'greenAlpha';

  return (
    <Tooltip label={qzLabel}>
      <Button colorScheme={qzColor}>
        <Icon as={FaPrint} />
      </Button>
    </Tooltip>
  );
};

export default QzInfo;
