import {createSlice} from '@reduxjs/toolkit';
import ipRegex from 'ip-regex';
import {useSelector} from 'react-redux';

import {RootState} from '@app/store';
import localIp from '@app/utils/localIp';

const slice = createSlice({
  name: 'ip',
  initialState: (await localIp()) as string,
  reducers: {},
  extraReducers: builder => {},
});

export const getCurrentIp = (state: RootState) => state.ip;

export const useCurrentIp = () => {
  const ip = useSelector(getCurrentIp);

  if (!ipRegex({exact: true}).test(ip)) {
    return false;
  }

  return ip;
};

export default slice.reducer;
