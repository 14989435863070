import {Box, Button, ButtonGroup, Flex, Icon, Tooltip, useColorMode} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {FaBug} from 'react-icons/fa';
import {FaArrowRightFromBracket, FaUserLock} from 'react-icons/fa6';
import {NavLink, useNavigate} from 'react-router-dom';

import IpInfo from '@app/layouts/Navbar/PrinterInfo/IpInfo';
import PrinterInfo from '@app/layouts/Navbar/PrinterInfo/PrinterInfo';
import QzInfo from '@app/layouts/Navbar/PrinterInfo/QzInfo';
import {useToken} from '@app/store/useToken';
import {UserAccess} from '@app/types/UserAccess';

const HeaderLinks: FC<{userAccess: UserAccess}> = ({userAccess}): ReactElement => {
  const {colorMode, toggleColorMode} = useColorMode();
  const {setToken} = useToken();
  const navigate = useNavigate();
  const logout = (): void => {
    setToken(null);
    navigate('/login');
  };

  return (
    <Flex w={{base: '100%', xl: 'auto'}} alignItems="center" flexDirection="row" justifyContent="flex-end" gap={2}>
      <QzInfo />
      <PrinterInfo />
      <IpInfo />
      {userAccess.adminUrl && (
        <NavLink key="admin" to={userAccess.adminUrl} target="_blank">
          <Button colorScheme="blackAlpha">
            <Icon as={FaUserLock} />
          </Button>
        </NavLink>
      )}
      {userAccess.debug && (
        <Tooltip label="Mode débug activé">
          <Button colorScheme="blackAlpha">
            <Icon as={FaBug} color="orange.400" />
          </Button>
        </Tooltip>
      )}
      {/*-<Button onClick={toggleColorMode}>{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}</Button>*/}
      <Button colorScheme="blackAlpha" onClick={() => logout()}>
        <Icon as={FaArrowRightFromBracket} />
      </Button>
    </Flex>
  );
};
export default HeaderLinks;
