import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {OrderCampaign} from '@app/types/OrderCampaign';
import {PickingCount} from '@app/types/PickingCount';
import {PickingPrint} from '@app/types/PickingPrint';
import {StatisticType} from '@app/types/Statistic/Statistic.ts';

export const statisticApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    today: builder.query<StatisticType, void>({
      query: () => ({
        url: Routing.generate('rest_statistic_today', {}, true),
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useTodayQuery} = statisticApi;
