// @ts-ignore
import {createSlice} from '@reduxjs/toolkit';
import qz from 'qz-tray';
import {useSelector} from 'react-redux';

import {RootState} from '@app/store';

const initialState = async () => {
  if (qz.websocket.isActive()) {
    return true;
  }
  return await qz.websocket
    .connect()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const slice = createSlice({
  name: 'qz',
  initialState: (await initialState()) as boolean,
  reducers: {},
  extraReducers: builder => {},
});

export const hasQz = (state: RootState) => state.qz;

export const useHasQz = () => {
  return useSelector(hasQz);
};

export default slice.reducer;
