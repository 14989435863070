import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {OrderCampaign} from '@app/types/OrderCampaign';
import {PickingCount} from '@app/types/PickingCount';
import {PickingPrint} from '@app/types/PickingPrint';

type PickingCreateCampaignMutation = {
  shopSlug: string;
  campaign: string;
};

export const pickingApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    pickingCount: builder.query<PickingCount, string>({
      query: shopSlug => ({
        url: Routing.generate('rest_picking_order_count', {slug: shopSlug}, true),
        method: 'GET',
      }),
    }),
    pickingListCampaign: builder.query<OrderCampaign[], string>({
      query: shopSlug => ({
        url: Routing.generate('rest_picking_order_campaign_list', {slug: shopSlug}, true),
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({campaign}) => ({type: 'pickingListCampaign', campaign}) as const),
              {type: 'pickingListCampaign', id: 'LIST'},
            ]
          : [{type: 'pickingListCampaign', id: 'LIST'}],
    }),
    pickingCreateMono: builder.query<PickingPrint | null, string>({
      query: shopSlug => ({
        url: Routing.generate('rest_picking_print_mono', {slug: shopSlug}, true),
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    pickingCreateMulti: builder.query<PickingPrint | null, string>({
      query: shopSlug => ({
        url: Routing.generate('rest_picking_print_multi', {slug: shopSlug}, true),
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    pickingCreateCampaign: builder.mutation<PickingPrint | null, PickingCreateCampaignMutation>({
      query: ({shopSlug, campaign}) => ({
        url: Routing.generate('rest_picking_print_campaign', {slug: shopSlug, campaign}, true),
        method: 'GET',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'pickingListCampaign', id: arg.campaign}],
    }),
  }),
});

export const {
  usePickingCountQuery,
  usePickingListCampaignQuery,
  useLazyPickingCreateMonoQuery,
  useLazyPickingCreateMultiQuery,
  usePickingCreateCampaignMutation,
} = pickingApi;
