import {HamburgerIcon} from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, {FC, ReactElement, useContext, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {SidebarContext} from '@app/layouts/Sidebar/SideBarContext';
import {UserAccess} from '@app/types/UserAccess';

import SidebarContent from './SidebarContent';

const SidebarResponsive: FC<{userAccess: UserAccess; display: any}> = ({userAccess, display}): ReactElement => {
  let location = useLocation();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const btnRef = useRef<SVGSVGElement | null>(null);
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.900');
  const {sidebarWidth} = useContext(SidebarContext);
  let sidebarBg = useColorModeValue('white', 'navy.800');
  let sidebarRadius = '20px';
  let sidebarMargins = '0px';
  let variantChange = '0.2s linear';

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [location]);

  return (
    <Box display={{base: 'flex', xl: 'none'}} alignItems="center">
      <HamburgerIcon color="#fff" w="30px" h="20px" ref={btnRef} cursor="pointer" onClick={onOpen} />
      <Drawer placement="left" isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          bg={sidebarBackgroundColor}
          maxW="250px"
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius="16px"
        >
          <DrawerCloseButton _focus={{boxShadow: 'none'}} _hover={{boxShadow: 'none'}} />
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100vh">
              <SidebarContent userAccess={userAccess} w="full" borderRight="none" />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
export default SidebarResponsive;
