import React, {FC, ReactElement} from 'react';

import CarrierSlipApp from '@app/components/Warehouse/CarrierSlip/CarrierSlipApp';
import LayoutWarehouse from '@app/components/templates/LayoutWarehouse';

const WarehouseCarrierSlip: FC = (): ReactElement => {
  return (
    <LayoutWarehouse appName="Imprimer bon de transport" needPrinterInfo>
      <CarrierSlipApp />
    </LayoutWarehouse>
  );
};

export default WarehouseCarrierSlip;
