import {SimpleGrid, Spinner} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';

import CarrierSlipButton from '@app/components/Warehouse/CarrierSlip/CarrierSlipButton';
import {useCarrierSlipListQuery} from '@app/store/camalo/carrierSlipApi';
import {CarrierSlip} from '@app/types/CarrierSlip/CarrierSlip';

const CarrierSlipApp: FC = (): ReactElement => {
  const {
    data: carrierSlipList,
    isLoading,
    refetch,
  } = useCarrierSlipListQuery(undefined, {refetchOnMountOrArgChange: true});

  if (isLoading) return <Spinner />;

  return (
    <SimpleGrid columns={2} spacing={10}>
      {carrierSlipList!.map((carrierSlip: CarrierSlip, index: number) => (
        <CarrierSlipButton key={index} carrierSlip={carrierSlip} onClosed={() => refetch()} />
      ))}
    </SimpleGrid>
  );
};
export default CarrierSlipApp;
