import React, {FC, ReactElement} from 'react';
import {Outlet, useOutletContext, useParams} from 'react-router-dom';

import {userAccessContext} from '@app/types/ContextType';

const ShopWrapper: FC = (): ReactElement => {
  const {userAccess, printerList} = useOutletContext<userAccessContext>();
  const {shopSlug} = useParams();
  const shop = userAccess.shops!.find(shop => shop.slug === shopSlug);

  return <Outlet context={{userAccess, printerList, shop: shop!}} />;
};

export default ShopWrapper;
