import React, {FC, ReactElement} from 'react';
import {FaCarCrash, FaClipboardList, FaInbox, FaInfoCircle, FaPaperPlane, FaPrint, FaUndo} from 'react-icons/fa';

import ButtonActionList from '@app/components/molecules/ButtonActionList';
import {ButtonType} from '@app/types/Common/ButtonType';
import {Role} from '@app/types/RoleEnum';
import {Shop} from '@app/types/Shop';

const ShopActions: FC<{shop: Shop}> = ({shop}): ReactElement => {
  const lines: ButtonType[][] = [
    [
      {
        label: 'Picking',
        icon: <FaClipboardList />,
        link: `/shop/${shop.slug}/picking`,
        role: Role.Picking,
        color: 'red',
        enabled: true,
      },
      {
        label: 'Expédition',
        icon: <FaPaperPlane />,
        link: `/shop/${shop.slug}/shipping`,
        role: Role.Shipping,
        color: 'red',
        enabled: true,
      },
      {
        label: 'Réception',
        icon: <FaInbox />,
        link: `/shop/${shop.slug}/reception`,
        role: Role.Reception,
        color: 'red',
        enabled: false,
      },
    ],
    [
      {
        label: 'Information commande',
        icon: <FaPrint />,
        link: `/shop/${shop.slug}/order-information`,
        role: Role.Print,
        color: 'blue',
        enabled: true,
      },
      {
        label: 'Information produit',
        icon: <FaInfoCircle />,
        link: `/shop/${shop.slug}/product-information`,
        role: Role.Info,
        color: 'blue',
        enabled: false,
      },
    ],
    [
      {
        label: 'Retour',
        icon: <FaUndo />,
        link: `/shop/${shop.slug}/order-return`,
        role: Role.ReturnProduct,
        color: 'orange',
        enabled: false,
      },
      {
        label: 'Rebut',
        icon: <FaCarCrash />,
        link: `/shop/${shop.slug}/rebut`,
        role: Role.Rebut,
        color: 'orange',
        enabled: false,
      },
    ],
  ];

  return <ButtonActionList lines={lines} roles={shop.roles!} />;
};

export default ShopActions;
