import {useToast} from '@chakra-ui/react';
import React, {FC, ReactElement, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import AlertQuantity from '@app/components/Warehouse/Print/_partial/AlertQuantity';
import InputLocation from '@app/components/Warehouse/Print/_partial/InputLocation';
import {useLazyLocationFindByPrefixQuery} from '@app/store/camalo/locationApi';
import {userAccessContext} from '@app/types/ContextType';
import {Printer} from '@app/types/Printer';
import {PrinterEnum} from '@app/types/PrinterEnum';
import {PrinterHandler} from '@app/utils/PrinterHandler';

const PrintBoxPrefix: FC = (): ReactElement => {
  const {printerList} = useOutletContext<userAccessContext>();
  const toast = useToast();
  const [needOpen, setNeedOpen] = useState<boolean>(false);
  const [boxes, setBoxes] = useState<string[]>([]);
  const [triggerLocationFindByPrefix, {isLoading}] = useLazyLocationFindByPrefixQuery();

  const searchAction = async (data: string): Promise<void> => {
    const response = await triggerLocationFindByPrefix(data).unwrap();
    setBoxes(response.locations);
    setNeedOpen(true);
  };

  const findPrinter = (): Printer | undefined => {
    return printerList.find((printer: Printer) => printer.type === PrinterEnum.Barcode);
  };

  const zplTemplate = (box: string): string[] => {
    return [`^XA\n^BY4,3,70\n^FO15,10,20^BCN,150,Y,N,N,A^FD${box}^FS\n^XZ\n`];
  };

  const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

  const handlePrint = async (data: number): Promise<void> => {
    setNeedOpen(false);
    const printer = findPrinter();

    if (!printer) {
      toast({
        title: 'Erreur',
        description: "Aucune imprimante n'est configurée",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    for (const location of boxes) {
      PrinterHandler.zpl(printer.originalName!, zplTemplate(location), data);
      await timer(500);
    }
  };

  return (
    <>
      <InputLocation
        title={`Préfix des caisses à imprimer`}
        placeholder={`Préfix des caisses`}
        btnName={`Rechercher`}
        searchAction={searchAction}
      />
      <AlertQuantity quantity={handlePrint} needOpen={needOpen} />
    </>
  );
};

export default PrintBoxPrefix;
