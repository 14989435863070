import {Card, Heading, IconButton, List, ListItem} from '@chakra-ui/react';
import React, {FC} from 'react';
import {FaPlus, FaPrint} from 'react-icons/fa';
import {useOutletContext} from 'react-router-dom';

import {replaceItemAtIndex} from '@app/helpers';
import {PrintHandler} from '@app/services/print/PrintHandler';
import {useAppDispatch} from '@app/store';
import {orderApi} from '@app/store/camalo/orderApi';
import {shipmentApi} from '@app/store/camalo/shipmentApi';
import {
  trackingNumberApi,
  useLazyTrackingNumberNewQuery,
  useLazyTrackingNumberReprintQuery,
} from '@app/store/camalo/trackingNumberApi';
import {usePrinterLog} from '@app/store/printerLogSlice';
import {shopContext} from '@app/types/ContextType';
import {Order} from '@app/types/Order/Information/Order';

const OrderTrackingNumber: FC<{order: Order}> = ({order}) => {
  const dispatch = useAppDispatch();
  const {shop, printerList} = useOutletContext<shopContext>();
  const [trackingNumberNew] = useLazyTrackingNumberNewQuery();
  const [trackingNumberReprint] = useLazyTrackingNumberReprintQuery();
  const {addLogs} = usePrinterLog();
  const addTrackingNumber = async (): Promise<void> => {
    const response = await trackingNumberNew({shopSlug: shop.slug!, order: order.id}).unwrap();
    const logs = PrintHandler.print(printerList, response, order.reference);
    addLogs(logs);
  };

  const reprintTrackingNumber = async (id: number): Promise<void> => {
    const response = await trackingNumberReprint({
      shopSlug: shop.slug!,
      order: order.id,
      trackingNumber: id,
    }).unwrap();
    const logs = PrintHandler.print(printerList, response, order.reference);
    addLogs(logs);
  };

  return (
    <Card mt={5} mx={2} p={3}>
      <Heading as="h4" mb="4">
        Numéros de suivi{' '}
        <IconButton aria-label={`Ajouter un numéro de suivi`} icon={<FaPlus />} ml={2} onClick={addTrackingNumber} />
      </Heading>
      <List>
        {order.trackingNumbers.map(trackingNumber => (
          <ListItem key={trackingNumber.id}>
            {trackingNumber.trackingNumber}{' '}
            <IconButton
              aria-label={`Réimprimer le numéro de suivi`}
              icon={<FaPrint />}
              ml={2}
              onClick={() => reprintTrackingNumber(trackingNumber.id)}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default OrderTrackingNumber;
