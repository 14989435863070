import {Card, Flex, Heading, List, ListItem, Table, TableContainer, Tbody, Td, Text, Thead, Tr} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import OrderTrackingNumber from '@app/components/Shop/OrderInformation/OrderTrackingNumber';
import {Order} from '@app/types/Order/Information/Order';

const OrderInformation: FC<{order: Order}> = ({order}): ReactElement => {
  return (
    <>
      <Flex>
        <Card mt={5} mx={2} p={3}>
          <Heading as="h4" mb="4">
            Commande
          </Heading>
          <List>
            <ListItem>
              <Text as="b">Numéro de commande</Text> : {order.id}
            </ListItem>
            <ListItem>
              <Text as="b">Référence interne</Text> : {order.reference}
            </ListItem>
            <ListItem>
              <Text as="b">Référence externe</Text> : {order.externalReference}
            </ListItem>
            <ListItem>
              <Text as="b">Numéro de commande externe</Text> : {order.externalId}
            </ListItem>
            <ListItem>
              <Text as="b">Date de commande</Text> : {order.dateAdd}
            </ListItem>
          </List>
        </Card>
        <OrderTrackingNumber order={order} />
      </Flex>
      <Card mt={5} mx={2} p={3}>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Td>
                  <Text as="b">Produit</Text>
                </Td>
                <Td>
                  <Text as="b">Quantité</Text>
                </Td>
                <Td>
                  <Text as="b">EAN13</Text>
                </Td>
                <Td>
                  <Text as="b">Prix</Text>
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              {order.carts.map((cart, index) => (
                <Tr key={index}>
                  <Td>
                    <Text>{cart.productName}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.quantity}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.productEan13}</Text>
                  </Td>
                  <Td>
                    <Text>{cart.price}</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default OrderInformation;
