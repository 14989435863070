import {useToast} from '@chakra-ui/react';
import React, {FC, ReactElement, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import AlertQuantity from '@app/components/Warehouse/Print/_partial/AlertQuantity';
import InputLocation from '@app/components/Warehouse/Print/_partial/InputLocation';
import {userAccessContext} from '@app/types/ContextType';
import {Printer} from '@app/types/Printer';
import {PrinterEnum} from '@app/types/PrinterEnum';
import {PrinterHandler} from '@app/utils/PrinterHandler';

const PrintLocation: FC = (): ReactElement => {
  const {printerList} = useOutletContext<userAccessContext>();
  const toast = useToast();
  const [needOpen, setNeedOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<string>();

  const searchAction = (data: string): void => {
    setLocation(data);
    setNeedOpen(true);
  };

  const findPrinter = (): Printer | undefined => {
    return printerList.find((printer: Printer) => printer.type === PrinterEnum.Barcode);
  };

  const zplTemplate = (): string[] => {
    let startBarcode = 100;
    let startText = 130;
    if (location!.length >= 9) {
      startBarcode = 90;
      startText = 120;
    }
    if (location!.length >= 11) {
      startBarcode = 65;
      startText = 80;
    }

    return [
      `^XA\n` +
        `^BY2,2,10\n` +
        `^FO${startBarcode},20^BCN,120,N,N,N^FD${location}^FS\n` +
        `^FO${startText},150^A0N,50,40^FD${location}^FS\n` +
        `^XZ\n`,
    ];
  };

  const handlePrint = (data: number): void => {
    setNeedOpen(false);
    const printer = findPrinter();

    if (!printer) {
      toast({
        title: 'Erreur',
        description: "Aucune imprimante n'est configurée",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    PrinterHandler.zpl(printer.originalName!, zplTemplate(), data);
  };

  return (
    <>
      <InputLocation
        title={'Impression code barre emplacement'}
        placeholder={'Code barre à imprimer'}
        searchAction={searchAction}
      />
      <AlertQuantity quantity={handlePrint} needOpen={needOpen} />
    </>
  );
};

export default PrintLocation;
