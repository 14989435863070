import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {Token} from '@app/types/Token';

const slice = createSlice({
  name: 'auth',
  initialState: {token: null} as Token,
  reducers: {
    setCredentials: (state, {payload}: PayloadAction<Token>) => {
      state.token = payload.token;
      localStorage.setItem('token', payload.token!);
    },
  },
  extraReducers: builder => {},
});

export const {setCredentials} = slice.actions;

export default slice.reducer;
