import Routing from '@app/store/camalo/routing';
import camaloModuleApi from '@app/store/camaloModuleApi';
import {PrintElement} from '@app/types/PrintElement';

export const trackingNumberApi = camaloModuleApi.injectEndpoints({
  endpoints: builder => ({
    trackingNumberNew: builder.query<PrintElement[], {shopSlug: string; order: number}>({
      query: ({shopSlug, order}) => ({
        url: Routing.generate('rest_order_tracking_number_new', {slug: shopSlug, order}, true),
        method: 'GET',
      }),
    }),
    trackingNumberReprint: builder.query<PrintElement[], {shopSlug: string; order: number; trackingNumber: number}>({
      query: ({shopSlug, order, trackingNumber}) => ({
        url: Routing.generate('rest_order_tracking_number', {slug: shopSlug, order, trackingNumber}, true),
        method: 'GET',
      }),
    }),
  }),
});

export const {useLazyTrackingNumberNewQuery, useLazyTrackingNumberReprintQuery} = trackingNumberApi;
