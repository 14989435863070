import React, {FC, ReactElement} from 'react';

import PickingCampaigns from '@app/components/Shop/Picking/PickingCampaigns';
import PickingDefault from '@app/components/Shop/Picking/PickingDefault';
import LayoutShop from '@app/components/templates/LayoutShop';

const ShopPicking: FC = (): ReactElement => {
  return (
    <LayoutShop appName="Picking" needPrinterInfo>
      <PickingDefault />
      <PickingCampaigns />
    </LayoutShop>
  );
};

export default ShopPicking;
