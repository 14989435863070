import React, {FC, ReactElement, ReactNode} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {useToken} from '@app/store/useToken';

const AuthGuard: FC<{children: ReactNode}> = ({children}): ReactElement => {
  const {token} = useToken();
  const location = useLocation();

  return token ? <>{children}</> : <Navigate to="/login" state={{from: location}} />;
};

export default AuthGuard;
