import {Avatar, Card, CardBody, CardFooter, CardHeader, Heading, Icon, useColorModeValue} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {MdPrint} from 'react-icons/md';

import InputButton from '@app/components/atoms/Button/InputButton';

const InputLocation: FC<{
  title: string;
  placeholder: string;
  btnName?: string;
  searchAction: (data: string) => void;
}> = ({title, placeholder, btnName, searchAction}): ReactElement => {
  const bg = useColorModeValue('white', 'gray.700');

  return (
    <>
      <Card bg={bg}>
        <CardHeader>
          <Heading>
            <Avatar icon={<Icon as={MdPrint} w={6} h={6} color="white" />} bg="red.500" mr={2} />
            {title}
          </Heading>
        </CardHeader>
        <CardBody>
          <InputButton placeholder={placeholder} btnName={btnName} searchValue={value => searchAction(value)} />
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </>
  );
};

export default InputLocation;
