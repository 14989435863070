import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import LayoutShop from '@app/components/templates/LayoutShop';
import {Shop} from '@app/types/Shop';

type ContextType = {shop: Shop};

const ShopOrderReturn: FC = (): ReactElement => {
  const {shop} = useOutletContext<ContextType>();

  return (
    <LayoutShop appName="Retour commande" needPrinterInfo>
      Retour commande {shop.name}
    </LayoutShop>
  );
};

export default ShopOrderReturn;
