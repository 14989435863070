import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import {RootState, useAppDispatch} from '@app/store';
import {PrintElement} from '@app/types/PrintElement';
import {PrintLog} from '@app/types/PrintLog';

const printerLogSlice = createSlice({
  name: 'printerLog',
  initialState: {
    logs: [] as PrintLog[],
  },
  reducers: {
    addNewLog: (state, action: PayloadAction<PrintLog>) => {
      state.logs.push(action.payload);
    },
    addNewLogs: (state, action: PayloadAction<PrintLog[]>) => {
      state.logs = [...state.logs, ...action.payload];
    },
    addNewLogFromPrinterElement: (state, action: PayloadAction<{printElement: PrintElement; pickingType: string}>) => {
      const printElement = action.payload.printElement;
      const type = action.payload.pickingType;
      const log: PrintLog = {
        date: new Date().toDateString(),
        name: printElement.title!,
        type,
        link: printElement.url,
      };
      state.logs.push(log);
    },
  },
});

const {addNewLog, addNewLogs, addNewLogFromPrinterElement} = printerLogSlice.actions;

export default printerLogSlice.reducer;

export const usePrinterLog = () => {
  const dispatch = useAppDispatch();
  const logs = useSelector((state: RootState) => state.printerLog.logs);

  const addLog = (log: PrintLog) => {
    dispatch(addNewLog(log));
  };
  const addLogs = (log: PrintLog[]) => {
    dispatch(addNewLogs(log));
  };

  const addLogFromPrinterElement = (printElement: PrintElement, pickingType: string) => {
    dispatch(addNewLogFromPrinterElement({printElement, pickingType}));
  };

  return {logs, addLog, addLogs, addLogFromPrinterElement};
};
