import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import {RootState, useAppDispatch} from '@app/store.ts';

type inputFocusType = {
  isFocused: boolean | null;
  needToFocus: boolean | null;
};

const slice = createSlice({
  name: 'inputFocus',
  initialState: {isFocused: null, needToFocus: null} as inputFocusType,
  reducers: {
    setNeedFocusSlice: state => {
      state.needToFocus = true;
    },
    setFocusedSlice: (state, {payload}: PayloadAction<{isFocused: boolean}>) => {
      state.isFocused = payload.isFocused;
      state.needToFocus = false;
    },
  },
  extraReducers: builder => {},
});

const {setNeedFocusSlice, setFocusedSlice} = slice.actions;

export default slice.reducer;

export const useInputFocus = () => {
  const isFocused = useSelector((state: RootState) => state.inputFocus.isFocused);
  const needToFocus = useSelector((state: RootState) => state.inputFocus.needToFocus);
  const dispatch = useAppDispatch();

  const setFocused = (value: boolean) => {
    dispatch(setFocusedSlice({isFocused: value}));
  };

  const setNeedFocus = () => {
    dispatch(setNeedFocusSlice());
  };

  return {isFocused, needToFocus, setFocused, setNeedFocus};
};
