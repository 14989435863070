import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import OrderInformationApp from '@app/components/Shop/OrderInformation/OrderInformationApp';
import LayoutShop from '@app/components/templates/LayoutShop';
import {shopContext} from '@app/types/ContextType';

const ShopOrderInformation: FC = (): ReactElement => {
  const {userAccess} = useOutletContext<shopContext>();

  return (
    <LayoutShop appName="Information commande" needPrinterInfo>
      <OrderInformationApp />
    </LayoutShop>
  );
};

export default ShopOrderInformation;
