import {Heading} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import WarehouseActions from '@app/components/Warehouse/WarehouseActions';
import {Warehouse} from '@app/types/Warehouse';

type ContextWarehouseType = {warehouse: Warehouse};
const WarehouseIndex: FC = (): ReactElement => {
  const {warehouse} = useOutletContext<ContextWarehouseType>();
  return (
    <>
      <Heading as="h3" size="lg" textAlign="center" color="white" mb={7} mt="0">
        Entrepôt
      </Heading>
      <WarehouseActions warehouse={warehouse} />
    </>
  );
};

export default WarehouseIndex;
