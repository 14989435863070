import {Button, Icon, Skeleton, Tooltip} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {FaDesktop} from 'react-icons/fa6';

import {useComputerExistQuery} from '@app/store/camalo/computerApi';

const PrinterInfo: FC = (): ReactElement => {
  const {isLoading, isFetching, isSuccess} = useComputerExistQuery();

  if (isLoading || isFetching) {
    return <Skeleton variant="rectangular" />;
  }

  let computerLabel = isSuccess ? 'Votre ordinateur est configuré' : "Votre ordinateur n'est pas configuré";
  let computerColor = !isSuccess ? 'redAlpha' : 'greenAlpha';
  return (
    <Tooltip label={computerLabel}>
      <Button colorScheme={computerColor}>
        <Icon as={FaDesktop} />
      </Button>
    </Tooltip>
  );
};

export default PrinterInfo;
