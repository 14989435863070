import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from '@app/store';
import Routing from '@app/store/camalo/routing';
import {Token} from '@app/types/Token';
import {UserAccess} from '@app/types/UserAccess';
import {UserLogin} from '@app/types/UserLogin';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers, {getState}) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    login: builder.mutation<Token, UserLogin>({
      query: userLogin => ({
        url: Routing.generate('api_login_check', {}, true),
        method: 'POST',
        body: userLogin,
      }),
    }),
    protected: builder.mutation({
      query: () => 'protected',
    }),
    userAccess: builder.query<UserAccess, void>({
      query: () => ({
        url: Routing.generate('rest_user_access', {}, true),
        method: 'GET',
      }),
    }),
  }),
});

export const {useLoginMutation, useProtectedMutation, useUserAccessQuery} = authApi;
