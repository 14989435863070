import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';
import {FaBug} from 'react-icons/fa';

import {usePrinterLog} from '@app/store/printerLogSlice';

const PrinterHistory: FC = (): ReactElement => {
  const {logs} = usePrinterLog();

  return (
    <Card overflowX={{sm: 'scroll', lg: 'hidden'}} mt={4}>
      <CardHeader>
        <Heading>
          <Icon as={FaBug} /> - Historique des impressions
        </Heading>
      </CardHeader>
      <CardBody>
        {logs.length === 0 && <p>Aucune impression</p>}
        {logs.length > 0 && (
          <TableContainer maxH="20vh" overflowY="scroll">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Nom</Th>
                  <Th>type</Th>
                  <Th>lien</Th>
                </Tr>
              </Thead>
              <Tbody>
                {logs.map((log, index) => (
                  <Tr key={index}>
                    <Td>{log.name}</Td>
                    <Td>{log.type}</Td>
                    <Td>
                      {log.link === '' ? (
                        ''
                      ) : (
                        <Link color="teal.500" href={log.link} isExternal>
                          {log.link}
                        </Link>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </CardBody>
    </Card>
  );
};

export default PrinterHistory;
