import {Card, CardBody} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import ReactApexChart from 'react-apexcharts';

import {ChartType} from '@app/types/Statistic/Statistic.ts';

export type ChartPieBlockProps = {
  chart: ChartType;
};

export const ChartPieBlock: FC<ChartPieBlockProps> = ({chart}): ReactElement => {
  return (
    <Card variant="elevated">
      <CardBody>
        <ReactApexChart options={{labels: chart.labels}} series={chart.data} type="pie" width={380} />
      </CardBody>
    </Card>
  );
};
