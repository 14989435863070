export enum Role {
  Info = 'INFO',
  Picking = 'PICKING',
  Rebut = 'REBUT',
  Reception = 'RECEPTION',
  Print = 'REPRINT',
  ReturnProduct = 'RETURN_PRODUCT',
  Shipping = 'SHIPPING',
  Inventory = 'INVENTORY',
  Transfer = 'TRANSFER',
  Barcode = 'BARCODE',
}
