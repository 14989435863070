import {Button} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {useOutletContext} from 'react-router-dom';

import {PrintHandler} from '@app/services/print/PrintHandler.ts';
import {useCarrierSlipCreateMutation} from '@app/store/camalo/carrierSlipApi';
import {usePrinterLog} from '@app/store/printerLogSlice';
import {CarrierSlip} from '@app/types/CarrierSlip/CarrierSlip';
import {userAccessContext} from '@app/types/ContextType';

const CarrierSlipButton: FC<{carrierSlip: CarrierSlip; onClosed: () => void}> = ({
  carrierSlip,
  onClosed,
}): ReactElement => {
  const {printerList} = useOutletContext<userAccessContext>();
  const {addLogs} = usePrinterLog();
  const [createCarrierSlip, {isLoading}] = useCarrierSlipCreateMutation();

  const handleClick = async (carrierSlip: CarrierSlip): Promise<void> => {
    const printElement = await createCarrierSlip(carrierSlip).unwrap();
    if (printElement === null) return onClosed();
    const logs = PrintHandler.print(printerList, [printElement], 'Bordereau ' + carrierSlip.name);
    addLogs(logs);
    onClosed();
  };

  return (
    <>
      <Button
        colorScheme={carrierSlip.orderCount > 0 ? 'red' : 'green'}
        h="20"
        width="100%"
        fontSize="2xl"
        textTransform={'uppercase'}
        isDisabled={carrierSlip.orderCount === 0}
        onClick={() => handleClick(carrierSlip)}
        isLoading={isLoading}
      >
        {carrierSlip.name + ' ' + carrierSlip.orderCount}
      </Button>
    </>
  );
};
export default CarrierSlipButton;
