import {Button, GridItem} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {Link} from 'react-router-dom';

import {ButtonType} from '@app/types/Common/ButtonType';

const ButtonAsLink: FC<{
  button: ButtonType;
}> = ({button}): ReactElement => {
  return (
    <GridItem as={Link} to={button.link} h="20">
      <Button
        leftIcon={button.icon}
        colorScheme={button.color}
        width="100%"
        height="100%"
        fontSize="2xl"
        border="2px"
        textTransform={'uppercase'}
        isDisabled={!button.enabled}
        whiteSpace="normal"
      >
        {button.label}
      </Button>
    </GridItem>
  );
};

export default ButtonAsLink;
