import {Box, useColorModeValue} from '@chakra-ui/react';
import React, {FC, ReactElement, useContext} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import {renderThumbDark, renderThumbLight, renderTrack, renderView} from '@app/layouts/Sidebar/ScrollBar/ScrollBar';
import {SidebarContext} from '@app/layouts/Sidebar/SideBarContext';
import {UserAccess} from '@app/types/UserAccess';

import SidebarContent from './SidebarContent';

const Sidebar: FC<{userAccess: UserAccess}> = ({userAccess}): ReactElement => {
  const {sidebarWidth, setSidebarWidth, toggleSidebar} = useContext(SidebarContext);
  let sidebarBg = useColorModeValue('white', 'navy.800');
  let sidebarRadius = '20px';
  let sidebarMargins = '0px';
  let variantChange = '0.2s linear';

  return (
    <Box
      onMouseEnter={toggleSidebar ? () => setSidebarWidth(sidebarWidth === 120 ? 275 : 120) : undefined}
      onMouseLeave={toggleSidebar ? () => setSidebarWidth(sidebarWidth === 275 ? 120 : 275) : undefined}
    >
      <Box display={{base: 'none', xl: 'block'}} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={`${sidebarWidth}px`}
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={useColorModeValue(renderThumbLight, renderThumbDark)}
            renderView={renderView}
          >
            <SidebarContent userAccess={userAccess} w="full" borderRight="none" />
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
};
export default Sidebar;
