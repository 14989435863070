import * as jose from 'jose';
import {useLocalStorage} from 'usehooks-ts';

export const TOKEN = 'token';

const isTokenExpired = (token: string): boolean => {
  const p = jose.decodeJwt(token);
  return p.exp !== undefined && p.exp < Math.floor(Date.now() / 1000);
};

export const useToken = () => {
  const [token, setToken] = useLocalStorage<string | null>(TOKEN, null);

  if (null !== token && isTokenExpired(token)) {
    setToken(null);
  }

  return {token, setToken};
};
