import React, {FC, ReactElement} from 'react';

import Inventory from '@app/components/Warehouse/Inventory/Inventory';
import LayoutWarehouse from '@app/components/templates/LayoutWarehouse';

const WarehouseInventory: FC = (): ReactElement => {
  return (
    <LayoutWarehouse appName="Inventaire">
      <Inventory />
    </LayoutWarehouse>
  );
};

export default WarehouseInventory;
