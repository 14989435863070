import {type ThemeConfig, extendTheme} from '@chakra-ui/react';
import {mode} from '@chakra-ui/theme-tools';
import '@fontsource/montserrat/latin-300.css';
import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-500.css';
import '@fontsource/montserrat/latin-600.css';
import '@fontsource/montserrat/latin-700.css';

import {buttonTheme} from '@app/theme/ButtonTheme';
import {card} from '@app/theme/Card';
import {inputTheme} from '@app/theme/InputTheme';
import {mainPanel} from '@app/theme/MainPanel';
import {panelContainer} from '@app/theme/PanelContainer';
import {panelContent} from '@app/theme/PanelContent';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  colors: {
    gray: {
      700: '#1f2733',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      700: '#1b254b',
      800: '#111c44',
      900: '#0b1437',
    },
    greenAlpha: {
      500: 'rgba(56, 161, 105, .7)',
    },
    redAlpha: {
      500: 'rgba(229, 62, 62, .7)',
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('gray.50', '#1B254B')(props),
        fontFamily: 'Montserrat, sans-serif',
      },
      html: {
        fontFamily: 'Montserrat, sans-serif',
      },
    }),
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    MainPanel: mainPanel,
    PanelContainer: panelContainer,
    PanelContent: panelContent,
    Card: card,
  },
});

export default theme;
